<style lang="scss">
.marktplaetze {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
    h2 {
    }
    .bild-box {
      width: 100%;

      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="content marktplaetze container">
    <h1>Marktplätze</h1>
    <p class="intro-txt">
      <strong>Sie suchen finanzkräftige Käufer für Ihr Wohnprojekt? Brauchen qualifizierte Mitarbeiter für Ihr Unternehmen? Ihre Gebrauchtfahrzeuge sollen neue Besitzer finden? </strong> Und Sie sollen einen Veranstaltungstipp teilen? Mit den Marktplätzen der Kleinen Zeitung – WOHNEN, BERUF &amp; BILDUNG, MOBILITÄT, VERANSTALTUNG &amp; KULINARIK – treffen Sie punktgenau auf Ihre Zielgruppe.
    </p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content//marktplaetze/bildung-beruf.jpg" alt="Beruf und Bildung" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Beruf &amp; Bildung</h2>
          <p>
            Platzieren Sie Ihre Anzeigen im größten Job-Markt Österreichs und finden Sie mit der Kleinen Zeitung Ihren Wunschmitarbeiter - Jeden Samstag (Hauptmarkt) und Mittwoch (Zweitmarkt) in der Zeitung.
          </p>
          <CollapsibleButton link="collapese-beruf-bildung" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Mehr Informationen zu Bildung &amp; Beruf</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="collapese-beruf-bildung">
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center">
          <h3>Produktdaten</h3>
          <p><strong>Anzeigenschluss</strong><br />Donnerstag, 15 Uhr für Samstag<br />Dienstag, 10 Uhr für Mittwoch</p>
          <p><strong>Wiederholungsschaltung</strong><br />50 % auf den Normaltarif bei Schaltung des gleichen Sujets wie bei Erstschaltung innerhalb von 14 Tagen</p>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content//marktplaetze/prod-beruf-bildung.png" alt="Beruf und Bildung" class="img-fluid" />
        </div>
      </div>
      <div class="tabelle-box tabelle-width-50">
        <div class="headline">
          <strong>Millimeterpreis 4C</strong>
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-3"></div>
          <div class="col-lg-3 text-align-right">Kombination</div>
          <div class="col-lg-3 text-align-right">Steiermark</div>
          <div class="col-lg-3 text-align-right">Kärnten</div>
        </div>
        <div class="row content-row">
          <div class="col-lg-3 text-align-center">MO - FR</div>
          <div class="col-6 text-align-left visible-small">Kombination</div>
          <div class="col-lg-3 col-6 text-align-right">20,68</div>
          <div class="col-6 text-align-left visible-small">Steiermark</div>
          <div class="col-lg-3 col-6 text-align-right">14,83</div>
          <div class="col-6 text-align-left visible-small">Kärnten</div>
          <div class="col-lg-3 col-6 text-align-right">9,07</div>
        </div>
        <div class="row content-row">
          <div class="col-lg-3 text-align-center">SA - SO</div>
          <div class="col-6 text-align-left visible-small">Kombination</div>
          <div class="col-lg-3 col-6 text-align-right">21,88</div>
          <div class="col-6 text-align-left visible-small">Steiermark</div>
          <div class="col-lg-3 col-6 text-align-right">15,48</div>
          <div class="col-6 text-align-left visible-small">Kärnten</div>
          <div class="col-lg-3 col-6 text-align-right">9,47</div>
        </div>
      </div>
      <h3>Raumanzeigen</h3>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/8 Seite </strong>
              Format: 47 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.791,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.002,05</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.224,45</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.953,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.089,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.278,45</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/4 Seite </strong>
              Format: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.583,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.004,10</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.448,90</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.907,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.179,60</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.556,90</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/2 Seite </strong>
              Format: 200 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.167,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.008,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.897,80</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.815,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.359,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5.113,80</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/1 Seite </strong>
              Format: 200 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">22.748,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">16.313,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9.977,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">24.068,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">17.028,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">10.417,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Tob-Job der Woche</strong>
              Format: 90 x 40 mm (inkl. Rahmen: 98 x 70 mm)
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">1.390,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">990,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">590,00</div>
            </div>
            <div class="info-txt-tabelle">Erscheinung nur samstags möglich. <br />Reservierungspflichtig: nur nach Verfügbarkeit buchbar.</div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-90x40.svg" alt="90x40" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Rubrikenvorspann</strong>
              Format: 200 x 110 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">1.990,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.500,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">900,00</div>
            </div>
            <div class="info-txt-tabelle">Erscheinung nur mittwochs möglich.<br />Pro Mittwochausgabe nur ein Rubrikenvorspann verfügbar.</div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-90x40.svg" alt="90x40" class="img-fluid " />
        </div>
      </div>

      <h3>Zeilenanzeigen</h3>
      <p>Ihre Möglichkeit, eine Zeilenanzeige im seriösen und reichweitenstarken Stellenmarkt im Süden Österreichs zu platzieren.</p>
      <div class="row">
        <div class="col-lg-8 justify-content-center align-self-center">
          <div class="tabelle-box alignItems">
            <div class="headline">
              <strong>Zeilenanzeigen </strong>
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Premium-Anzeigen</span> (inkludiert 15 Zeilen Text, zweizeiliges Logo)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">428,26</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">335,33</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">287,02</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Basis-Anzeigen</span> (inkludiert 5 Zeilen Text)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">84,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">54,81</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">40,74</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Jede weitere Zeile</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">16,48</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">13,01</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,54</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Willhaben-Code</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Wiederholung</span> (Zweitmarkt-Schaltmöglichkeit am Mittwoch)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 justify-content-center align-self-center">
          <img src="@/assets/img/img-content//marktplaetze/bsp-beruf-bildung.png" alt="Bsp. Beruf und Bildung" class="img-fluid" />
        </div>
      </div>
    </Collapsible>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content//marktplaetze/wohnen.jpg" alt="Beruf und Bildung" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Wohnen</h2>
          <p>
            Redaktionelle Themenberichte, spannende Wohnportraits und die besten Immobilienangebote im Süden Österreichs geben unseren Lesern einen Überblick über den heimischen Immobilienmarkt. Platzieren Sie Ihre Anzeige in diesem attraktiven Umfeld. Der Wohnenteil der Kleinen Zeitung erscheint jeden Freitag (Hauptmarkt) und Mittwoch (Zweitmarkt).
          </p>
          <CollapsibleButton link="collapese-wohnen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Mehr Informationen zu Wohnen</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="collapese-wohnen">
      <h3>Ihre Werbemöglichkeiten</h3>
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
          <div class="tabelle-box ">
            <div class="headline">
              <strong>MIllimeterpreis 4C</strong>
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">20,68</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">14,83</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,07</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">21,88</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">15,48</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,47</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content//marktplaetze/prod-wohnen.png" alt="Beruf und Bildung" class="img-fluid" />
        </div>
      </div>

      <h3>Raumanzeigen</h3>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/8 Seite </strong>
              Format: 47 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.791,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.002,05</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.224,45</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.953,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.089,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.278,45</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/4 Seite </strong>
              Format: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.583,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.004,10</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.448,90</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.907,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.179,60</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.556,90</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/2 Seite </strong>
              Format: 200 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.167,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.008,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.897,80</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.815,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.359,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5.113,80</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/1 Seite </strong>
              Format: 200 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">22.748,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">16.313,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9.977,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">24.068,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">17.028,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">10.417,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
        </div>
      </div>

      <h3>Zeilenanzeigen</h3>
      <p>Ihre Möglichkeit, eine Zeilenanzeige im seriösen und reichweitenstarken Immobilienmarkt im Süden Österreichs zu platzieren</p>
      <div class="row">
        <div class="col-lg-8 justify-content-center align-self-center">
          <div class="tabelle-box alignItems">
            <div class="headline">
              <strong>Zeilenanzeigen </strong>
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Premium-Anzeigen</span> (inkludiert Farbbild, 5 Zeilen Text, zweizeiliges Logo, Energiezeile und Preiszeile)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">180,83</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">130,44</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">96,99</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Basis-Anzeigen</span> (inkludiert 4 Zeilen Text, Energie- und Preiszeile)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">70,82</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">45,62</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">33,58</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Jede weitere Zeile</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11,82</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">7,56</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5,64</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Logo 4c</span> je Zeile</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">16,48</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">13,01</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,54</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Farbbild</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">65,23</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">51,24</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">38,69</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Icon</span> je Icon (max. 3)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Willhaben-Code</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Energiezeile</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Wiederholung</span> (Zweitmarkt-Schaltmöglichkeit am Mittwoch)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 justify-content-center align-self-center">
          <img src="@/assets/img/img-content//marktplaetze/bsp-wohnen.png" alt="Bsp. Wohnen" class="img-fluid" />
        </div>
      </div>
    </Collapsible>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content//marktplaetze/mobilitaet.jpg" alt="Mobilität" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Mobilität</h2>
          <p>Ihre Anzeige im reichweitenstarken Auto- &amp; Motor-Markt im Süden Österreichs. Der Mobilitäts-Teil erscheint jeden Freitag (Hauptmarkt) und Sonntag (Zweitmarkt) in der Kleinen Zeitung. Nutzen Sie dieses attraktive Umfeld und sprechen Sie genau Ihre Kunden an.</p>
          <CollapsibleButton link="collapese-mobilitaet" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Mehr Informationen zu Wohnen</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="collapese-mobilitaet">
      <h3>Ihre Werbemöglichkeiten</h3>
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
          <div class="tabelle-box ">
            <div class="headline">
              <strong>MIllimeterpreis 4C</strong>
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">20,68</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">14,83</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,07</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">21,88</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">15,48</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,47</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content//marktplaetze/prod-mobilitaet.png" alt="Mobilität" class="img-fluid" />
        </div>
      </div>

      <h3>Raumanzeigen</h3>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/8 Seite </strong>
              Format: 47 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.791,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.002,05</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.224,45</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.953,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.089,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.278,45</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/4 Seite </strong>
              Format: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.583,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.004,10</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.448,90</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.907,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.179,60</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.556,90</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/2 Seite </strong>
              Format: 200 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.167,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.008,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.897,80</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.815,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.359,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5.113,80</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/1 Seite </strong>
              Format: 200 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MI</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">22.748,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">16.313,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9.977,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">24.068,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">17.028,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">10.417,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
        </div>
      </div>
      <h3>Zeilenanzeigen</h3>
      <p>Ihre Möglichkeit, eine Zeilenanzeige im seriösen und reichweitenstarken Auto- &amp; Motor-Markt im Süden Österreichs zu platzieren</p>
      <div class="row">
        <div class="col-lg-8 justify-content-center align-self-center">
          <div class="tabelle-box alignItems">
            <div class="headline">
              <strong>Zeilenanzeigen </strong>
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Premium-Anzeigen</span> (inkludiert Farbbild, 4 Zeilen Text, zweizeiliges Logo und Preiszeile)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">162,01</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">106,37</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">86,26</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Basis-Anzeigen</span> (inkludiert 4 Zeilen Text und Preiszeile)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">63,82</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">40,62</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">30,58</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Jede weitere Zeile</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11,82</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">7,56</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5,64</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Logo 4c</span> je Zeile</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">16,48</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">13,01</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,54</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Farbbild</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">65,23</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">39,73</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">36,60</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Icon</span> je Icon (max. 3)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Willhaben-Code</span></div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-left"><span class="strong">Wiederholung</span> (Zweitmarkt-Schaltmöglichkeit am Mittwoch)</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">-50%</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 justify-content-center align-self-center">
          <img src="@/assets/img/img-content//marktplaetze/bsp-mobilitaet.png" alt="Bsp. Mobilität" class="img-fluid" />
        </div>
      </div>
    </Collapsible>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content//marktplaetze/veranstaltung-kulinarik.jpg" alt="Veranstaltungen &amp; Kulinarik" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Veranstaltungen &amp; Kulinarik</h2>
          <p>
            Top aktuell und garantiert regional. Bewerben Sie Ihre Veranstaltungen und kulinarischen Gaumenfreuden punktgenau in einer Regionalausgabe der Kleinen Zeitung.
          </p>
          <a href="../../pdf/marktplaetze/2186-1907-Veranstaltungen+Kulinarik-Flyer-KOMBI.pdf" target="_blank" class="content-link-mehrinfo"> <span class="material-icons">arrow_right_alt</span>Mehr Informationen zu Veranstaltungen &amp; Kulinarik </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'Marktplaetze',
  components: {
    CollapsibleButton,
    Collapsible,
  },
  data() {
    return {
      toggle: false,
      toggle2: false,
    };
  },
});
</script>
